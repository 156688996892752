import { createSlice } from '@reduxjs/toolkit';

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {
    open: false,
    children: {
      title: '',
      children: {
        title: '',
        children: '',
      },
      clean: false,
    },
    content: {
      title: '',
      description: '',
      buttons: [],
    },
  },
  reducers: {
    openDialog: (state) => {
      state.open = true;
    },
    closeDialog: (state) => {
      state.open = false;
    },
    setChildren: (state, action) => {
      state.children = {
        title: action.payload.title,
        children: action.payload.children,
        clean: action.payload.clean,
      };
      state.content = {};
      state.open = true;
    },
    setContent: (state, action) => {
      state.children = '';
      state.content = {
        title: action.payload.title,
        description: action.payload.description,
        description1: action.payload.description1,
        description2: action.payload.description2,
        buttons: action.payload.buttons,
      };
      state.open = true;
    },
    setContextualContent: (state, action) => {
      state.children = '';
      state.content = {
        title: action.payload.title,
        description: action.payload.description,
        type: 'contextual',
      };
      state.open = true;
    },
  },
});

export const { openDialog, closeDialog, setChildren, setContent, setContextualContent } = dialogSlice.actions;
export default dialogSlice.reducer;
