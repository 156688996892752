import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../store/features/dialog';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import useStyles from './globalDialogStyles';
import BlackWhiteButton from '../../components/Buttons/blackWhite';
import { CloseRounded } from '@mui/icons-material';

function GlobalDialog(props) {
  const dialog = useSelector((state) => state.dialog);
  const classes = useStyles({ type: dialog.content.type });

  const dispatch = useDispatch();

  return (
    <Dialog
      onClose={(e, reason) => {
        if (reason !== 'backdropClick') {
          dispatch(closeDialog());
        }
      }}
      classes={{
        paper:
          dialog.children?.title === 'Request Details' ||
          dialog.children.title === 'Response to client' ||
          dialog.children.title === 'Response Log' ||
          dialog.children.title === 'Message Log' ||
          dialog.children.title === 'Request Info'
            ? classes.requestpaper
            : classes.paper,
      }}
      open={dialog.open}
    >
      {dialog.content?.title && (
        <Typography
          variant="tabText"
          sx={{ marginBottom: '20px', color: dialog.content?.title && dialog.content?.title === 'existing' && 'transparent' }}
        >
          {dialog.content.title}
        </Typography>
      )}
      {dialog.children?.title && (
        <Typography variant="tabText" sx={{ marginBottom: '20px' }}>
          {dialog.children?.title}
        </Typography>
      )}
      <IconButton className={classes.closeIcon} onClick={() => dispatch(closeDialog())}>
        <CloseRounded />
      </IconButton>
      <Paper
        elevation={0}
        className={
          dialog.children?.title === 'Request Details' ||
          dialog.children.title === 'Response to client' ||
          dialog.children.title === 'Response Log' ||
          dialog.children.title === 'Message Log' ||
          dialog.children.title === 'Request Info'
            ? classes.requestroot
            : classes.root
        }
        sx={dialog.children?.clean ? { padding: '0px !important' } : {}}
      >
        {dialog.children?.children && dialog.children.children}
        {dialog.content?.title && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignitems: 'center',
              justifyContent: 'center',
              gap: '50px',
            }}
          >
            {dialog.content.description && (
              <Typography textAlign="center" variant="body1">
                {dialog.content.description}
              </Typography>
            )}
            {dialog.content.description1 && (
              <Typography textAlign="left" variant="body1">
                {dialog.content.description1}
              </Typography>
            )}
            {dialog.content.description2 && (
              <Typography style={{ marginTop: '-20px' }} textAlign="left" variant="body1">
                {dialog.content.description2}
              </Typography>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
              {dialog.content.buttons?.map((item) => (
                <BlackWhiteButton
                  sx={{ minWidth: '100px !important' }}
                  title={item.title}
                  onClick={() => {
                    if (item.onClick) {
                      item.onClick();
                    }
                    dispatch(closeDialog());
                  }}
                />
              ))}
            </Box>
          </Box>
        )}
      </Paper>
    </Dialog>
  );
}

export default GlobalDialog;
