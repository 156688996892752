import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  site_name: '',
  siteName: JSON.parse(localStorage.getItem('site_name')),
  availsiteid: '',
  availsitename: '',
  appointmentsitename: '',
  appointmentservicetype: '',
  appointmentsiteid: '',
  fromsitesetupmultiple: false,
};

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    site: (state, action) => {
      state.site_name = action.payload;
      localStorage.setItem('site_name', JSON.stringify(action.payload));
    },
    availsiteId: (state, action) => {
      state.availsiteid = action.payload;
    },
    availsiteName: (state, action) => {
      state.availsitename = action.payload;
    },
    availsiteName: (state, action) => {
      state.availsitename = action.payload;
    },
    appointmentSitename: (state, action) => {
      state.appointmentsitename = action.payload;
    },
    appointmentServicetype: (state, action) => {
      state.appointmentservicetype = action.payload;
    },
    appointmentSiteid: (state, action) => {
      state.appointmentsiteid = action.payload;
    },
    fromsitesetupMultiple: (state, action) => {
      state.fromsitesetupmultiple = action.payload;
    },
  },
});

export const { site, availsiteId, availsiteName, appointmentSitename, appointmentServicetype, appointmentSiteid, fromsitesetupMultiple } =
  siteSlice.actions;

export default siteSlice.reducer;
