import { ButtonBase, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '6px 15px',
    borderRadius: '30px',
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.normalBlue,
    },
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.normalBlue}`,
    boxShadow: theme.shadows[1],
    height: '34px',
    maxHeight: '34px',
  },
}));

const BlackWhiteButton = (props) => {
  const classes = useStyles();
  return (
    <>
      <ButtonBase
        type="button"
        {...props}
        className={classes.button}
        style={{
          border:
            (props?.deletepickup === true && localStorage.getItem('create4thobj') && '1px solid lightgrey') ||
            (props?.deletedropoff === true && localStorage.getItem('create4thobj') && '1px solid lightgrey'),
        }}
      >
        {props.icon && <props.icon />}
        <Typography
          sx={props.typosx || {}}
          variant="blackButtonText"
          style={{
            color:
              (props?.deletepickup === true && localStorage.getItem('create4thobj') && 'lightgrey') ||
              (props?.deletedropoff === true && localStorage.getItem('create4thobj') && 'lightgrey'),
          }}
        >
          {props.title}
        </Typography>
      </ButtonBase>
    </>
  );
};

export default BlackWhiteButton;
