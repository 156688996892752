/* eslint-disable eqeqeq */
/* eslint-disable import/no-anonymous-default-export */
import { httpClient } from './api';
import { storage } from './storage';
import { ServiceCaller } from './ServiceCaller';
import store from '../store';
import { closeLoader, openLoader } from '../store/features/loader';
import axios from 'axios';

//const BASE_URL = 'http://sensez9-elb-644860358.us-east-1.elb.amazonaws.com/be';
const BASE_URL = 'https://linkserv.sensez9.tech/be';

export default {
  setupInterceptors: () => {
    httpClient.interceptors.request.use(
      async (config) => {
        if (config.showLoader !== false) {
          // Do not show the loader for this specific request
          store.dispatch(openLoader());
        }
        // if (
        //   config.url.startsWith("http://3.80.38.16:8081/v1") &&
        //   config.url != "http://3.80.38.16:8081/v1/auth" &&
        //   config.url != "http://3.80.38.16:8081/v1/refresh_token" &&
        //   config.url != "http://3.80.38.16:8081/v1/validateRegistrationKey"
        // ) {
        //   // console.log(storage.isLoggedIn())
        //   // console.log(store.getState().user.accessToken)
        //   config.headers.Authorization = `Bearer ${store.getState().user.accessToken || localStorage.getItem('access')}`;
        //   return config;
        // }

        // if (
        //   config.url.startsWith('http://sensez9-elb-644860358.us-east-1.elb.amazonaws.com/be/v1') &&
        //   config.url != 'http://sensez9-elb-644860358.us-east-1.elb.amazonaws.com/be/v1/auth' &&
        //   config.url != 'http://sensez9-elb-644860358.us-east-1.elb.amazonaws.com/be/v1/refresh_token' &&
        //   config.url != 'http://sensez9-elb-644860358.us-east-1.elb.amazonaws.com/be/v1/validateRegistrationKey' &&
        //   config.url != 'http://sensez9-elb-644860358.us-east-1.elb.amazonaws.com/be/v1/registerUser' &&
        //   config.url != 'http://sensez9-elb-644860358.us-east-1.elb.amazonaws.com/be/v1/resetPasswordWithEmail' &&
        //   !config.url.includes('forgetPasswordEmail')
        // )
        
        if (
          config.url.startsWith('https://linkserv.sensez9.tech/be/v1') &&
          config.url != 'https://linkserv.sensez9.tech/be/v1/auth' &&
          config.url != 'https://linkserv.sensez9.tech/be/v1/refresh_token' &&
          config.url != 'https://linkserv.sensez9.tech/be/v1/validateRegistrationKey' &&
          config.url != 'https://linkserv.sensez9.tech/be/v1/registerUser' &&
          config.url != 'https://linkserv.sensez9.tech/be/v1/resetPasswordWithEmail' &&
          !config.url.includes('forgetPasswordEmail')
        )
        {
          let curToken = store.getState().user.refreshToken || localStorage.getItem('211refresh');

          if (!curToken) {
            window.location.replace('/login');
          }
          let token = '';
          try {
            const response = await axios({
              url: `${BASE_URL}/v1/refresh_token`,
              method: 'POST',
              data: { refresh_token: curToken },
            });
            token = response.data.access_token;
          } catch (err) {
            if (err.response.status === 401) {
              window.location.replace('/login');
            }
          }
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        }

        return config;
      },
      function (err) {
        return Promise.reject(err);
      }
    );

    httpClient.interceptors.response.use(
      (response) => {
        store.dispatch(closeLoader());
        return response;
      },
      (error) => {
        store.dispatch(closeLoader());
        if (error.response && error.response.status === 401) {
        }
        if (error.response && error.response.status === 403) {
        }

        return Promise.reject(error);
      }
    );
  },
};
