import { configureStore } from '@reduxjs/toolkit';
import loaderReducer from './features/loader';
import userReducer from './features/user';
import toastReducer from './features/toast';
import registrationReducer from './features/registration';
import siteCoordinatorClientRequirementsReducer from './features/siteCoordinatorClientRequirementsReducer';
import roleReducer from './features/roles';
import dialogReducer from './features/dialog';
import filterReducer from './features/filter';
import siteReducer from './features/site';
const store = configureStore({
  reducer: {
    loader: loaderReducer,
    user: userReducer,
    toast: toastReducer,
    registration: registrationReducer,
    siteCoordinatorClientRequirements: siteCoordinatorClientRequirementsReducer,
    roles: roleReducer,
    dialog: dialogReducer,
    filter: filterReducer,
    site: siteReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
