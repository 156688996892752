import { createSlice } from '@reduxjs/toolkit';

const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    items: [],
    tempItems: [],
    navigatorEligibilityPhone: [],
    eligibilitycountyname: '',
    setupsitename: '',
    setmultiplescsiteid: '',
    saveticket: {},
  },
  reducers: {
    setFilters: (state, action) => {
      state.items = action.payload;
    },
    setTemporaryFilters: (state, action) => {
      state.tempItems = action.payload;
    },
    setNavigatorEligibilityPhone: (state, action) => {
      state.navigatorEligibilityPhone = action.payload;
    },
    setRegionSiteid: (state, action) => {
      state.regionsiteid = action.payload;
    },
    seteligibilitycountyName: (state, action) => {
      state.eligibilitycountyname = action.payload;
    },
    setsetupsiteName: (state, action) => {
      state.setupsitename = action.payload;
    },
    setmultiplescsiteId: (state, action) => {
      state.setmultiplescsiteid = action.payload;
    },
    setsaveticket: (state, action) => {
      state.saveticket = action.payload;
    },
  },
});

export const {
  setFilters,
  setTemporaryFilters,
  setNavigatorEligibilityPhone,
  setRegionSiteid,
  seteligibilitycountyName,
  setsetupsiteName,
  setmultiplescsiteId,
  setsaveticket,
} = filterSlice.actions;
export default filterSlice.reducer;
