import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import { getUTCDATE } from './constant';
import store from '../store';

export const generateAvatarName = (firstName = 'Admin', lastName) => {
  // Get the first letter of each name and capitalize them
  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();

  // Combine the initials to create the avatar name
  const avatarName = firstInitial + lastInitial;

  return avatarName;
};
export function sortDataAscendingByLabel(data) {
  // Sorting the data by label in ascending order
  data?.sort((a, b) => a.label.localeCompare(b.label));
  return data;
}
export function sortDataAscendingByValue(data) {
  // Sorting the data by label in ascending order
  data?.sort((a, b) => a.value.localeCompare(b.value));
  return data;
}
export function sortDataAscendingByTitle(data) {
  // Sorting the data by label in ascending order
  data?.sort((a, b) => a.title.localeCompare(b.title));
  return data;
}
export function sortDataAscendingByRolename(data) {
  // Sorting the data by label in ascending order
  data?.sort((a, b) => a.role_name.localeCompare(b.role_name));
  return data;
}
export function sortDataAscending(data) {
  // Sorting the data by label in ascending order
  data?.sort((a, b) => a.localeCompare(b));
  return data;
}

export function isValidValue(value) {
  // Check if the value is not null and not undefined
  if (value === null || typeof value === 'undefined') {
    return false;
  }

  // Check if the value is a string and not just whitespace
  if (typeof value === 'string' && value.trim() === '') {
    return false;
  }

  return true;
}

export function formatPhoneNumber(phoneNumber) {
  // Remove any non-digit characters from the phone number
  const digits = phoneNumber?.replace(/\D/g, '');

  // Check if the remaining digits length is valid for formatting
  if (digits?.length !== 10) {
    return phoneNumber; // Return original input if not a valid phone number
  }

  // Format the phone number as (XXX) XXX XXXX
  const formattedPhoneNumber = `(${digits.substr(0, 3)}) ${digits.substr(3, 3)} ${digits.substr(6)}`;
  return formattedPhoneNumber;
}

export const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const daysOfWeek2 = [
  { small: 'Sun', big: 'Sunday' },
  { small: 'Mon', big: 'Monday' },
  { small: 'Tue', big: 'Tuesday' },
  { small: 'Wed', big: 'Wednesday' },
  { small: 'Thu', big: 'Thursday' },
  { small: 'Fri', big: 'Friday' },
  { small: 'Sat', big: 'Saturday' },
];
export function checkForNull(arr) {
  return arr.some((element) => element == null);
}

const BuildScheduleData = (schedule) => {
  // console.log(JSON.parse(JSON.stringify(schedule)), 'buildsc');
  return schedule
    ?.filter((x) => x.schedule_type !== 2)
    .map((item, index) => {
      return {
        id: uuid(),
        scheduleId: item.site_schedules_master_id,
        sType: item.schedule_type,
        siteStart: dayjs(getUTCDATE(item.start_date)).format('MM/DD/YYYY'),
        siteEnd: dayjs(getUTCDATE(item.end_date)).format('MM/DD/YYYY'),
        dates: [
          {
            id: 'initialId',
            customMasterid: item.site_schedules_master_id,
            preparerStart: item?.start_date === item.end_date ? dayjs(getUTCDATE(item.start_date)) : dayjs(null),
            preparerEnd: item?.start_date === item.end_date ? dayjs(getUTCDATE(item.end_date)) : dayjs(null),
            days: daysOfWeek
              .filter((dayItem) => {
                return item[dayItem.toLowerCase()] === 1;
              })
              .map((dayItem) => {
                let scheduleItem = item.siteWeeklyScheduleList.find((w) => w.weekday === dayItem.toLowerCase());
                if (scheduleItem) {
                  return {
                    title: dayItem,
                    on: false,
                    siteStartTime: dayjs(scheduleItem.start_time, 'HH:mm:ss').format('hh:mm A'),
                    siteEndTime: dayjs(scheduleItem.end_time, 'HH:mm:ss').format('hh:mm A'),
                    siteBreak: scheduleItem.siteBreakMappingList.map((breakItem) => ({
                      start: dayjs(breakItem.start_break_time, 'HH:mm:ss').format('hh:mm A'),
                      end: dayjs(breakItem.end_break_time, 'HH:mm:ss').format('hh:mm A'),
                    })),
                    slotCount: 1,
                    slotServiceTypeMappingList: [
                      {
                        id: `${dayItem}-${uuid()}-0`,
                        expanded: true,
                        slotData: [
                          {
                            id: uuid(),
                            start_time: '',
                            end_time: '',
                            services: [{ label: '', value: '' }],
                            addItionalServices: [],
                          },
                        ],
                      },
                    ],
                    preparerTimeSlots: [
                      {
                        start: '',
                        end: '',
                        id: uuid(),
                      },
                    ],
                  };
                }
              }),
          },
        ],
      };
    });
};
export const scheduleBuildData = (schedule, updated) => {
  // console.log(JSON.parse(JSON.stringify(schedule)), updated, 'schedule, updated');

  /************update data hai to this condition */
  if (updated && updated.length > 0) {
    let updatedScheduleData = updated
      .filter((item) => item?.schedule_type === 0) //filter out exceptions
      .map((data) => {
        return schedule.find((sData) => data.customMasterId === sData.site_schedules_master_id); //map data acoording to matched id
      });

    // console.log(JSON.parse(JSON.stringify(updatedScheduleData)), checkForNull(updatedScheduleData), ',,,,,,,,,');

    //if not matched make default data
    if (checkForNull(updatedScheduleData)) {
      return BuildScheduleData(schedule);
    }
    //inner data mapped
    const returnData = updatedScheduleData.map((item, i) => {
      const findIndex =
        updatedScheduleData.length === updated.filter((item) => item?.schedule_type === 0).length
          ? i
          : updated.findIndex((u) => u.customMasterId === item.site_schedules_master_id);

      if (findIndex === -1) {
        return {
          id: uuid(),
          scheduleId: item.site_schedules_master_id,
          sType: item.schedule_type,
          siteStart: dayjs(getUTCDATE(item.start_date)).format('MM/DD/YYYY'),
          siteEnd: dayjs(getUTCDATE(item.end_date)).format('MM/DD/YYYY'),
          dates: [
            {
              id: 'initialId',
              customMasterid: item.site_schedules_master_id,
              preparerStart: item?.start_date === item.end_date ? dayjs(getUTCDATE(item.start_date)) : dayjs(null),
              preparerEnd: item?.start_date === item.end_date ? dayjs(getUTCDATE(item.end_date)) : dayjs(null),
              days: daysOfWeek
                .filter((dayItem) => {
                  return item[dayItem.toLowerCase()] === 1;
                })
                .map((dayItem) => {
                  let scheduleItem = item.siteWeeklyScheduleList.find((w) => w.weekday === dayItem.toLowerCase());

                  return {
                    title: dayItem,
                    on: false,
                    siteStartTime: dayjs(scheduleItem.start_time, 'HH:mm:ss').format('hh:mm A'),
                    siteEndTime: dayjs(scheduleItem.end_time, 'HH:mm:ss').format('hh:mm A'),
                    siteBreak: scheduleItem.siteBreakMappingList.map((breakItem) => ({
                      start: dayjs(breakItem.start_break_time, 'HH:mm:ss').format('hh:mm A'),
                      end: dayjs(breakItem.end_break_time, 'HH:mm:ss').format('hh:mm A'),
                    })),
                    slotCount: 1,
                    slotServiceTypeMappingList: [
                      {
                        id: `${dayItem}-${uuid()}-0`,
                        expanded: true,
                        slotData: [
                          {
                            id: uuid(),
                            start_time: '',
                            end_time: '',
                            services: [{}],
                            addItionalServices: [{}],
                          },
                        ],
                      },
                    ],
                    preparerTimeSlots: [
                      {
                        start: '',
                        end: '',
                        id: uuid(),
                      },
                    ],
                  };
                }),
            },
          ],
        };
      }
      const updatedData = updated[findIndex];
      return {
        id: uuid(),
        scheduleId: item?.site_schedules_master_id,
        sType: item.schedule_type,
        siteStart: dayjs(getUTCDATE(item?.start_date)).format('MM/DD/YYYY'),
        siteEnd: dayjs(getUTCDATE(item?.end_date)).format('MM/DD/YYYY'),
        added_dates: updatedData?.added_dates || 0,
        dates: [
          {
            id: 'initialId',
            customMasterid: item?.site_schedules_master_id,
            sequence: 1,
            preparerStart:
              item?.start_date === item.end_date ? dayjs(getUTCDATE(item?.start_date)) : dayjs(getUTCDATE(updatedData?.start_date)),
            preparerEnd: item?.start_date === item.end_date ? dayjs(getUTCDATE(item?.end_date)) : dayjs(getUTCDATE(updatedData?.end_date)),

            days: daysOfWeek
              .filter((dayItem) => {
                return item && item[dayItem.toLowerCase()] === 1;
              })
              .map((dayItem) => {
                let scheduleItem = item.siteWeeklyScheduleList.find((w) => w.weekday === dayItem.toLowerCase());
                const dayDay = updatedData.slotWeeklyScheduleResponses.filter((x) => dayItem.toLowerCase() === x.dayId)?.[0];
                const slotCount = dayDay && dayDay?.slotServiceTypeMappingLists.length ? dayDay.slotServiceTypeMappingLists.length : 1;
                const slotServiceTypeMappingList =
                  dayDay && dayDay?.slotServiceTypeMappingLists?.length > 0
                    ? dayDay.slotServiceTypeMappingLists.map((slotData) => {
                        return {
                          id: slotData.day_wise_slot_id,
                          expanded: true,
                          slotData:
                            slotData.slotDataList.length > 0
                              ? slotData.slotDataList.map((slotList) => {
                                  const services = slotList.services
                                    .filter((service) => service.service_type === '1')
                                    .map((x) => ({
                                      label: `${x.service_type_name} (${x.service_type_duration})`,
                                      value: x.service_type_name,
                                    }));
                                  // .map((service) => service.service_type_duration);
                                  const addItionalServices = slotList.services
                                    .filter((service) => service.service_type === '2')
                                    .map((x) => {
                                      return {
                                        label: `${x.service_type_name} (${x.service_type_duration})`,
                                        value: x.service_type_name,
                                      };
                                    });
                                  return {
                                    id: uuid(),
                                    updated: true,
                                    start_time: dayjs(dayjs(slotList.start_time, 'hh:mm A').format('lll')),
                                    end_time: dayjs(dayjs(slotList.end_time, 'hh:mm A').format('lll')),
                                    services: services.length > 0 ? services : [{ label: '', value: '' }],
                                    addItionalServices: addItionalServices.length > 0 ? addItionalServices : [],
                                  };
                                })
                              : [
                                  {
                                    id: uuid(),
                                    updated: false,
                                    start_time: '',
                                    end_time: '',
                                    services: [{ label: '', value: '' }],
                                    addItionalServices: [],
                                  },
                                ],
                        };
                      })
                    : [
                        {
                          id: `${dayItem}-${uuid()}-0`,
                          expanded: true,
                          slotData: [
                            {
                              id: uuid(),
                              updated: false,
                              start_time: '',
                              end_time: '',
                              services: [{ label: '', value: '' }],
                              addItionalServices: [],
                            },
                          ],
                        },
                      ];
                return {
                  title: dayItem,
                  on: dayDay ? true : false,
                  siteStartTime: dayjs(scheduleItem.start_time, 'HH:mm:ss').format('hh:mm A'),
                  siteEndTime: dayjs(scheduleItem.end_time, 'HH:mm:ss').format('hh:mm A'),
                  siteBreak: scheduleItem.siteBreakMappingList.map((breakItem) => ({
                    start: dayjs(breakItem.start_break_time, 'HH:mm:ss').format('hh:mm A'),
                    end: dayjs(breakItem.end_break_time, 'HH:mm:ss').format('hh:mm A'),
                  })),
                  slotCount: slotCount,
                  slotServiceTypeMappingList: slotServiceTypeMappingList,
                  preparerTimeSlots: [
                    {
                      start: '',
                      end: '',
                      id: uuid(),
                    },
                  ],
                };
              }),
          },
        ],
      };
    });
    //left records jinme data nhi hai
    const leftRecoreds = schedule.filter((data) => returnData.findIndex((u) => u.scheduleId === data.site_schedules_master_id) === -1);
    // console.log(JSON.parse(JSON.stringify(leftRecoreds)), returnData, 'returnData');

    if (leftRecoreds.length > 0) {
      return [...returnData, ...BuildScheduleData(leftRecoreds)];
    }

    return returnData;
  }
  //if not updated data then goes to this block
  return BuildScheduleData(schedule);
};
export const exceptiomsBuildData = (masterdata, schedule) => {
  if (schedule?.length > 0) {
    const exceptionArr = schedule
      .filter((i) => i.schedule_type !== 0)
      ?.map((exceptionItem) => {
        const returnObject = {
          id: uuid(),
          date: dayjs(getUTCDATE(exceptionItem.start_date)),
          isHighlighted: exceptionItem.is_highlighted === 1,
          schedule_type: exceptionItem.schedule_type,
          added_dates: exceptionItem?.added_dates || 0,
          customMasterid: exceptionItem.customMasterId,
          sequence: 1,
          preparerStart: dayjs(getUTCDATE(exceptionItem?.start_date)),
          preparerEnd: dayjs(getUTCDATE(exceptionItem?.end_date)),
          siteStart: exceptionItem.site_start_date,
          siteEnd: exceptionItem.site_end_date,
          siteBreak: JSON.parse(exceptionItem.site_break),
        };
        const dayItem = exceptionItem.slotWeeklyScheduleResponses[0];
        returnObject.title = dayItem.title.slice(0, 3);
        returnObject.slotCount = dayItem.slotServiceTypeMappingLists?.length;
        returnObject.slotServiceTypeMappingList = dayItem.slotServiceTypeMappingLists.map((slotData) => {
          return {
            id: slotData.day_wise_slot_id,
            expanded: true,
            slotData:
              slotData.slotDataList.length > 0
                ? slotData.slotDataList.map((slotList) => {
                    const services = slotList.services
                      .filter((service) => service.service_type === '1')
                      .map((x) => ({
                        label: `${x.service_type_name} (${x.service_type_duration})`,
                        value: x.service_type_name,
                      }));
                    // .map((service) => service.service_type_duration);
                    const addItionalServices = slotList.services
                      .filter((service) => service.service_type === '2')
                      .map((x) => {
                        return {
                          label: `${x.service_type_name} (${x.service_type_duration})`,
                          value: x.service_type_name,
                        };
                      });
                    return {
                      id: uuid(),
                      updated: true,
                      start_time: dayjs(dayjs(slotList.start_time, 'hh:mm A').format('lll')),
                      end_time: dayjs(dayjs(slotList.end_time, 'hh:mm A').format('lll')),
                      services: services.length > 0 ? services : [{ label: '', value: '' }],
                      addItionalServices: addItionalServices.length > 0 ? addItionalServices : [],
                    };
                  })
                : [
                    {
                      id: uuid(),
                      updated: false,
                      start_time: '',
                      end_time: '',
                      services: [{ label: '', value: '' }],
                      addItionalServices: [],
                    },
                  ],
          };
        });
        return returnObject;
      });
    return exceptionArr;
  }
  return [];
};

export const formArrFromTree = (obj, user) => {
  let menuArr = [];
  let newContentArr;
  newContentArr = obj.tabContent.map((firstLevelItem) => {
    let curItem = firstLevelItem;
    if (firstLevelItem.children && firstLevelItem.children.length > 0) {
      curItem.children = firstLevelItem.children.map((secondLevelItem) => {
        let curItem = secondLevelItem;
        if (secondLevelItem.children && secondLevelItem.children.length > 0) {
          curItem.children = secondLevelItem.children.map((thirdLevelItem) => {
            let curItem = thirdLevelItem;
            let idAdded = { id: uuid(), ...curItem };
            return idAdded;
          });
        }
        let idAdded = { id: uuid(), ...curItem };
        return idAdded;
      });
    }
    let idAdded = { id: uuid(), ...curItem };
    return idAdded;
  });

  const parseTitle = (title) => {
    return title.replaceAll(' ', '-').replaceAll('/', '-').toLowerCase();
  };

  newContentArr.map((firstItem) => {
    menuArr.push({
      id: firstItem.id,
      title: firstItem.title,
      fromId: 'TABCONTENT',
      route: `/${user.role_name.replace(' ', '-').toLowerCase()}/home/${parseTitle(firstItem.title)}`,
      toId: firstItem.children ? [...firstItem.children?.map((childItem) => childItem.id)] : [],
    });
    if (firstItem.children && firstItem.children.length > 0) {
      firstItem.children.map((secondItem) => {
        menuArr.push({
          id: secondItem.id,
          title: secondItem.title,
          fromId: firstItem.id,
          route: `/${user.role_name.replace(' ', '-').toLowerCase()}/home/${
            parseTitle(firstItem.title) === 'setup' ? 'setup-details' : parseTitle(firstItem.title)
          }/${parseTitle(secondItem.title)}`,
          toId: secondItem.children ? [...secondItem.children?.map((childItem) => childItem.id)] : [],
        });
        if (secondItem.children && secondItem.children.length > 0) {
          secondItem.children.map((thirdItem) => {
            menuArr.push({
              id: thirdItem.id,
              title: thirdItem.title,
              fromId: secondItem.id,
              route: `/${user.role_name.replace(' ', '-').toLowerCase()}/home/${
                parseTitle(firstItem.title) === 'setup' ? 'setup-details' : parseTitle(firstItem.title)
              }/${parseTitle(secondItem.title)}/${parseTitle(thirdItem.title)}`,
            });
          });
        }
      });
    }
  });
  return menuArr;
};

export const getDesc = (title) => {
  switch (title) {
    case 'Appointments':
      return 'Create, edit or cancel client appointments';
    case 'Setup':
      return 'Configure sites, Invite users, Create service provider and slot-based availability';
    case 'Reports':
      return 'Download reports and history logs';
    case 'Sites':
      return 'Set up basic information and services';
    case 'Users':
      return 'Create users and invite them to register';
    case 'Service Providers & Availability':
      return 'Add or edit service provider service types. Set up service provider availability.';
    case 'Availability':
      return 'Add or edit slots and set hours and services by slot.';
    case 'Create New Appointment':
      return 'Determine eligibility and service type, and book appointment at the best place and time for the client';
    case 'Edit/Cancel Appointment':
      return 'Search existing appointments to edit or cancel';
    case 'Eligibility':
      return 'Create county eligibility flow and publish to sites';
    case 'User Roles & Rights':
      return 'Create user roles and assign rights';
    case 'Regions & Sites':
      return 'Create regions and link to states and county. Create site and map to region/county';
    case 'Region & Sites':
      return 'Create regions and link to states and county. Create site and map to region/county';
    case 'Service Types':
      return 'Set up basic information and services';
  }
};
export const StringDisplay = ({ inputString }) => {
  // Split the input string into an array of substrings
  const substrings = inputString.split(',');

  // Create groups of two substrings
  const groupedSubstrings = [];
  for (let i = 0; i < substrings.length; i += 2) {
    groupedSubstrings.push(substrings.slice(i, i + 2).join(','));
  }

  return (
    <div>
      {groupedSubstrings.map((group, index) => (
        <div key={index}>{group}</div>
      ))}
    </div>
  );
};
