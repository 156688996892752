import { createSlice } from '@reduxjs/toolkit';

const toastSlice = createSlice({
  name: 'toast',
  initialState: { open: false, severity: '', message: '' },
  reducers: {
    openToast: (state, action) => {
      state.open = true;
      state.severity = action.payload.severity;
      state.message = action.payload.message;
    },
    closeToast: (state) => {
      state.open = false;
      state.severity = '';
      state.message = '';
    },
  },
});

export const { closeToast, openToast } = toastSlice.actions;
export default toastSlice.reducer;
