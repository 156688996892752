import { createSlice } from '@reduxjs/toolkit';

const roleReducer = createSlice({
  name: 'roles',
  initialState: { roles: [] },
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
  },
});

export const { setRoles } = roleReducer.actions;
export default roleReducer.reducer;
