import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '20px',
    background: (props) => (props.type === 'contextual' ? theme.palette.lightBlue : theme.palette.darkBlue),
    padding: '20px',
  },
  requestpaper: {
    width: '100%',
    borderRadius: '20px',
    background: (props) => (props.type === 'contextual' ? theme.palette.lightBlue : theme.palette.darkBlue),
    padding: '20px',
  },
  // paper2: {
  //   borderRadius: '20px',
  //   // background: (props) => (props.type === 'contextual' ? theme.palette.lightBlue : theme.palette.darkBlue),
  //   padding: '20px',
  // },
  root: {
    padding: '45px 25px 30px 25px',
    borderRadius: '10px',
    maxWidth: '500px',
    minWidth: '400px',
    position: 'relative',
  },
  requestroot: {
    padding: '45px 25px 30px 25px',
    borderRadius: '10px',
    maxWidth: '100%',
    minWidth: '400px',
    position: 'relative',
  },
  root2: {
    padding: '45px 25px 30px 25px',
    borderRadius: '10px',
    // maxWidth: '500px',
    minWidth: '400px',
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    border: `2.5px solid ${theme.palette.darkBlue}`,
    height: '30px',
    width: '30px',
    backgroundColor: 'white',
    color: `${theme.palette.darkBlue} !important`,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

export default useStyles;
