import { useLocation, useNavigate } from 'react-router-dom';
import Loader from './components/Loader';
import './index.css';
import AppRoutes from './routes';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeToast } from './store/features/toast';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import GlobalDialog from './common/CustomDialog/globalDialog';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/login');
    }
  }, []);

  const open = useSelector((state) => state.loader.open);
  const toast = useSelector((state) => state.toast);

  const handleToastClose = () => {
    dispatch(closeToast());
  };

  return (
    <div className="App">
      <Snackbar sx={{ zIndex: 1000 }} open={toast.open} autoHideDuration={4000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} severity={toast.severity} sx={{ width: '100%', fontSize: '16px' }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <GlobalDialog />
      {open ? <Loader /> : null}
      <AppRoutes />
    </div>
  );
}

export default App;
