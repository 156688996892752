import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: 'loader',
  initialState: { open: 0 },
  reducers: {
    openLoader: (state) => {
      state.open += 1;
    },
    closeLoader: (state) => {
      if (state.open !== 0) {
        state.open -= 1;
      }
    },
  },
});

export const { openLoader, closeLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
