import { createSlice } from '@reduxjs/toolkit';

const registrationSlice = createSlice({
  name: 'registration',
  initialState: { email: '', phone: '', role: '', roleId: '' },
  reducers: {
    setRegistrationData: (state, action) => {
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.role = action.payload.role;
      state.roleId = action.payload.roleId;
      state.registrationKey = action.payload.registrationKey;
    },
  },
});

export const { setRegistrationData } = registrationSlice.actions;
export default registrationSlice.reducer;
