import { STORAGE_USER, AUTH_USER_DATA, STORAGE_USER_DATA } from './actionConstants';

class Storage {
  getUser() {
    return JSON.parse(localStorage.getItem(STORAGE_USER_DATA));
  }

  setUser(user) {
    localStorage.setItem(STORAGE_USER_DATA, JSON.stringify(user));
  }

  getAuthData() {
    return JSON.parse(localStorage.getItem(AUTH_USER_DATA));
  }

  setAuthData(user) {
    localStorage.setItem(AUTH_USER_DATA, JSON.stringify(user));
  }

  getJwtToken() {
    if (localStorage.getItem(STORAGE_USER_DATA) !== 'undefined') {
      let obj = JSON.parse(localStorage.getItem(STORAGE_USER_DATA));
      if (obj && obj.access_token && obj.access_token.length > 0) {
        return obj.access_token;
      }
    }
    return '';
  }

  getRefreshToken() {
    if (localStorage.getItem(STORAGE_USER_DATA) !== 'undefined') {
      let obj = JSON.parse(localStorage.getItem(STORAGE_USER_DATA));

      if (obj && obj.refresh_token && obj.refresh_token.length > 0) {
        return obj.refresh_token;
      }
    }
    return '';
  }

  getUserRole() {
    if (localStorage.getItem(STORAGE_USER_DATA) !== 'undefined') {
      let obj = JSON.parse(localStorage.getItem(STORAGE_USER_DATA));
      if (obj && obj.roleSet && obj.roleSet.length > 0) {
        return (obj && obj.roleSet && obj.roleSet[0]) || '';
      }
    }
    return '';
  }

  isUserId() {
    if (localStorage.getItem(STORAGE_USER_DATA) !== 'undefined') {
      let obj = JSON.parse(localStorage.getItem(STORAGE_USER_DATA));
      if (obj && obj.userId) {
        return (obj && obj.userId) || '';
      }
    }
    return false;
  }

  isLoggedIn() {
    if (localStorage.getItem(STORAGE_USER_DATA) !== 'undefined') {
      let obj = JSON.parse(localStorage.getItem(STORAGE_USER_DATA));

      if (obj && obj?.userMaster?.user_id) {
        return true;
      }
    }
    return false;
  }
  clear() {
    localStorage.clear();
  }
}
let storage = new Storage();
export { storage };
