export const tempUser = {
  firstName: 'Joe',
  lastName: 'Smith',
  userEmail: 'Joesmith@gmail.com',
  designation: 'Site Coordinator',
  imageUrl: 'https://example.com/avatar.jpg', // Provide the image URL or leave it undefined
};

export const getUTCDATE = (date) => {
  try {
    const dayjs = require('dayjs');
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);
    const dateConverted = dayjs.utc(date);
    return dateConverted.format('MM/DD/YYYY');
  } catch (error) {
    return date;
  }
};

export const userMenuLinksEditProfile = (role) => {
  let editProfile = '/' + role + '/edit-profile';
  return editProfile;
};
export const userMenuLinksTrainingVideo = (role) => {
  let trainingvideo = '/' + role + '/home/Training-video';
  return trainingvideo;
};
export const userMenuLinksResetPassword = (role) => {
  let resetPassword = '/' + role + '/reset-password';
  return resetPassword;
};

export const userMenuLinks = {
  editProfileSite: '/site-coordinator/edit-profile',
  editProfile: '/client-Admin/edit-profile',
  resetPassword: '/site-coordinator/reset-password',
};

export const URL = {
  LOGIN_PAGE: '/login',
  HOME_PAGE: '/client-admin/home',
  RESET_WITH_EMAIL: '/change-password',
};
export const NAVIGATORURL = {
  HOME_PAGE: '/navigator/home',
};
export const SERVICEPROVIDERURL = {
  HOME_PAGE: '/service-provider/home',
};
export const SITECORDURL = {
  LOGIN_PAGE: '/login',
  HOME_PAGE: '/site-coordinator/home',
  RESET_WITH_EMAIL: '/change-password',
};

export const STRING = {
  FORGOT_PASSWORD: 'Forgot/Reset Password',
};
