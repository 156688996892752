// export const API_URL = "http://3.80.38.16:8081/";
//export const API_URL = 'http://sensez9-elb-644860358.us-east-1.elb.amazonaws.com/be/';
export const API_URL = 'https://linkserv.sensez9.tech/be/';

export const REFRESH_TOKEN_URL = `${API_URL}v1/refresh_token`;
export const AUTH_API = `${API_URL}v1/auth`;
export const GET_ALL_ROLES = `${API_URL}v1/getAllRoles`;
export const GET_ALL_ROLES_BY_CLIENT_ROLE = `${API_URL}v1/getAllRolesByClientIdAndRole`;
export const GET_TRAINING_VIDEO_BY_ROLENAME = `${API_URL}v1/getTrainingVideosByRoleName`;
export const GENERATE_INVITE = `${API_URL}v1/addUser`;
export const VALIDATE_REGISTRATION_KEY = `${API_URL}v1/validateRegistrationKey`;
export const REGISTER = `${API_URL}v1/registerUser`;
export const GET_INVITED_USERS = `${API_URL}v1/getAllInvitesByClientId`;
export const GET_INVITED_USERS_BY_USER_ID = `${API_URL}v1/getAllInvitesByClientIdAndUserId`;
export const GET_MENU_STRUCTURE = `${API_URL}v1/getMenuStructureByRoleName`;
export const GET_PASSWORD_HISTORY = `${API_URL}v1/getPasswordHistoryByUser`;
//REGION
export const GET_ALL_STATES = `${API_URL}v1/distinctStatesFromZipCodes`;
export const GET_COUNTY_BY_STATE = `${API_URL}v1/distinctCountiesFromZipCodes`;
export const CREATE_REGION = `${API_URL}v1/createRegion`;
export const GET_COUNTIES_FROM_ZIP_CODES = `${API_URL}v1/getCountiesFromZipCodes`;
export const GET_REGION_BY_CLIENT_ID = `${API_URL}v1/getRegionByClientId`;
export const DELETE_REGION = `${API_URL}v1/deleteRegion`;
export const UPDATE_REGION = `${API_URL}v1/updateRegion`;
export const GET_REGION_LIST_BY_CLIENT_AND_COUNTY = `${API_URL}v1/getRegionListByClientIdAndCounty`;
export const GET_REGION_LIST_BY_CLIENT_AND_COUNTY_TOKEN = `${API_URL}v1/getRegionListByClientIdAndCountyToken`;
export const GET_COUNTIES_BY_MULTIPLE_REGION = `${API_URL}v1/getCountiesByMultipleRegionId`;
export const REGION_LIST_PAGINATION_FILTER = `${API_URL}v1/getRegionByClientIdPaginationAndFilter`;

//SITE
export const GET_SITES_BY_MULTIPLE_COUNTIES_REGION = `${API_URL}v1/getSiteListByClientIdAndMultipleCountyWithRegion`;

export const CREATE_SITE_LIST = `${API_URL}v1/createSiteList`;
export const GET_SITE_LIST_BY_CLIENT_ID = `${API_URL}v1/getSiteListByClientId`;
export const GET_SITE_LIST_BY_CLIENT_ID_WITH_USER = `${API_URL}v1/getSiteListByClientIdAndUserId`;
export const GET_SITE_LIST_BY_CLIENT_ID_AND_COUNTY = `${API_URL}v1/getSiteListByClientIdAndCounty`;
export const GET_SITE_LIST_BY_CLIENT_ID_AND_COUNTY_WITH_REGION = `${API_URL}v1/getSiteListByClientIdAndCountyAndRegion`;
export const GET_SITE_LIST_BY_CLIENT_ID_AND_COUNTYV1 = `${API_URL}v1/getSiteListByClientIdAndCountyWithRegionv1`;
export const GET_SITE_LIST_BY_CLIENT_ID_AND_COUNTY_WITH_USER = `${API_URL}v1/getSiteListByClientIdAndCountyWithUserId`;
export const GET_SITE_LIST_BY_CLIENT_ID_AND_COUNTY_WITH_USER_FOR_NAVIGATORS = `${API_URL}v1/getSiteListByClientIdAndCountyWithUserIdForNavigators`;

export const GET_COUNTY_BY_REGION_ID = `${API_URL}v1/getCountyByRegionId`;
export const GET_COUNTY_BY_REGION_ID_WITH_USER = `${API_URL}v1/getCountyByRegionIdAndUserId`;
export const GET_COUNTY_BY_REGION_ID_WITH_USER_NAVIGATOR = `${API_URL}v1/getCountyByRegionIdAndUserIdForNavigators`;

export const GET_COUNTY_BY_REGION_ID_FOR_ELIGIBILITY = `${API_URL}v1/getCountyByRegionIdForEligibility`;
export const GET_PUBLISHED_COUNTY_BY_REGION_ID_FOR_ELIGIBILITY = `${API_URL}v1/getPublishedCountyByRegionIdForEligibility`;
export const UPDATE_SITE = `${API_URL}v1/updateSiteList`;
export const DELETE_SITE = `${API_URL}v1/deleteSite`;
export const GET_SITE_BASIC_DETAILS = `${API_URL}v1/getAllSiteBasicDetails`;
export const GET_SITE_BASIC_DETAILSV1 = `${API_URL}v1/getAllSiteBasicDetailsForPreparerOrSlot`;
export const GET_SITE_SCHEDULE = `${API_URL}v1/getSiteSchedulebySiteId`;
export const SAVE_SITE_BASIC_DETAILS = `${API_URL}v1/saveSiteBasicDetails`;
export const UPDATE_SITE_BASIC_DETAILS = `${API_URL}v1/updateSiteBasicDetails`;
//export const SAVE_SITE_SCHEDULE = `${API_URL}v1/updateSiteScheduleV2`;
export const SAVE_SITE_SCHEDULE = `${API_URL}v1/updateSiteScheduleV3`;
export const CREATE_EXCEPTION_FOR_SITE_SCHEDULE = `${API_URL}v1/createExceptionForSiteV2`;
export const SAVE_SITE_SERVICE = `${API_URL}v1/updateSiteServiceV2`;
export const SAVE_SITE_SPECIAL_CONSIDERATIONS = `${API_URL}v1/saveSiteSpecialConsiderations`;
export const SAVE_SITE_INFORMATION = `${API_URL}v1/saveSiteInformation`;
export const SAVE_SITE_WORKFLOWS = `${API_URL}v1/saveSiteWorkflows`;
export const SAVE_SITE_VISIBILITY = `${API_URL}v1/saveSiteVisibility`;
export const SITE_LIST_PAGINATION_FILTER = `${API_URL}v1/getSiteListByClientIdPaginationAndFilter`;
export const SITE_LIST_SAVE_USER_GEOGRAPHY_MAPPING = `${API_URL}v1/saveUserGeographyMapping`;
export const GET_SITE_SERVICE_TYPE_MAPPING_BY_SITE_ID = `${API_URL}v1/getSiteServiceTypeMappingBySiteId`;
export const GET_SITE_CONFIG_STATUS_BY_SITEID = `${API_URL}v1/getSiteConfigStatusBySiteId`;

//SERVICE TYPE
export const CREATE_SERVICE_TYPE = `${API_URL}v1/createServiceType`;
export const CREATE_ADDITIONAL_SERVICE_TYPE = `${API_URL}v1/createAdditionalServiceType`;
export const CREATE_ALL_SERVICE_TYPE = `${API_URL}v1/getAllServiceTypeByClientId`;
export const DELETE_SERVICE_TYPE = `${API_URL}v1/deleteServiceType`;
export const UPDATE_SERVICE_TYPE = `${API_URL}v1/updateServiceType`;
export const UPDATE_ADDITIONAL_SERVICE_TYPE = `${API_URL}v1/updateAdditionalServiceType`;
export const GET_ALL_SERVICES_CLIENTID_PAGINATION_FILTER = `${API_URL}v1/getAllServiceTypeByClientIdPaginationAndFilter`;
export const GET_ALL_SERVICE_BY_CLIENT_ID = `${API_URL}v1/getAllServiceTypeByClientId`;
export const SAVE_PREPARER_SERVICE = `${API_URL}v1/updatePreparerServiceV1`;
//ROLES
export const CREATE_ROLE = `${API_URL}v1/createRole`;
export const GET_ALL_ROLES_BY_CLIENT_ID = `${API_URL}v1/getAllRolesByClientId`;
export const ASSIGN_ROLE_PERMISSIONS = `${API_URL}v1/updateRoleRightsMapping`;
export const GET_ROLE_PERMISSIONS = `${API_URL}v1/getRoleRightsMappingByRoleId`;
export const DELETE_ROLE = `${API_URL}v1/deleteRole`;
export const UPDATE_ROLE = `${API_URL}v1/updateRole`;
export const CHANGE_ROLE = `${API_URL}v1/changeRoleByEmail`;

//MAP To Geography
export const GET_MAP_TO_GEOGRAPHY_LIST = `${API_URL}v1/getAllRegisteredUserListByClientId`;
export const GET_MAP_TO_GEOGRAPHY_LIST_BY_SITE_ID = `${API_URL}v1/getAllRegisteredUserListByClientIdAndUserId`;
export const DELETE_MAP_TO_GEOGRAPHY_LIST = `${API_URL}v1/deleteUserGeographyMapping`;
export const DELETE_MAP_TO_INVITE_USER_LIST = `${API_URL}v1/deleteInvite`;

// CLIENT REQUIREMENTS
export const GET_ALL_SERVICES = `${API_URL}v1/getServiceTypeBySiteId`;
export const GET_CALENDAR_AVAIALABLE_DATES = `${API_URL}v1/getAvailableDatesForCalendarByServiceTypeV1`;
export const GET_AVAILABLE_TIME_SLOTS = `${API_URL}v1/getAvailableTimeSlotBySiteIdAndTaxtypeAndDateNew`;
export const GET_CITY_BY_ZIP_CODE = `${API_URL}v1/getCityByZipCode`;
export const CREATE_APPOINTMENT = `${API_URL}v1/createAppointment`;
export const UPDATE_APPOINTMENT_CLIENT = `${API_URL}v1/updateAppointmentClientDetails`;
export const SEND_APPOINTMENT_NOTIFICATION = `${API_URL}v1/sendUserNotification`;
export const UPDATE_APPOINTMENT_CLIENT_DETAILS = `${API_URL}v1/updateAppointmentDetails`;

//USERS
export const GET_ALL_INVITES_BY_CLIENT_PAGINATION_FILTER = `${API_URL}v1/getAllInvitesByClientIdPaginationAndFilter`;
export const GET_ALL_REGISTERED_BY_CLIENT_PAGINATION_FILTER = `${API_URL}v1/getAllRegisteredUserListByClientIdAndPaginationAndFilter`;
export const GET_ALL_RESENDINVITE = `${API_URL}v1/resendInvite`;

//preparer
export const GET_PREPARER_SERVICE_TYPE_MAPPING_SITEID = `${API_URL}v1/getPreparerServiceTypeMappingBySiteId`;
export const GET_PREPARER_BY_SITE_ID = `${API_URL}v1/getPreparerBySiteId`;
export const SAVE_PREPARER_SCHEDULE = `${API_URL}v1/updatePreparerScheduleV3`;
export const GET_PREPARER_SERVICE_BY_PREPARER_ID = `${API_URL}v1/getPreparerServiceTypeMappingByPreparerId`;
export const GET_PREPARER_SCHEDULE_BY_PREPARER_ID = `${API_URL}v1/getPreparerScheduleByPreparerId`;
export const GET_PREPARER_SCHEDULE_BY_PREPARER_ID_FOR_CALENDAR_SPECIFIC = `${API_URL}v1/getPreparerScheduleByPreparerIdForDayCalendar`;
export const DELETE_PREPARE_SITE_MAPPING = `${API_URL}v1/deletePreparerSiteMapping`;
export const GET_PREPARER_CONFIG_STATUS = `${API_URL}v1/getPreparerConfigStatusByPreparerId`;
export const GET_PREPARER_SCHEDULE_FOR_CALENDER = `${API_URL}v1/getPreparerScheduleByPreparerIdForCalendar`;
export const CREATE_EXCEPTION_FOR_PREPARER = `${API_URL}v1/createExceptionForPreparer`;

// ELIGIBILITY BUILDER
export const GET_ALL_SAVED_QUESTIONS_BY_COUNTY = `${API_URL}v1/getAllQuestionsByCounty`;
export const SAVE_QUESTION_FOR_ELIGIBILITY = `${API_URL}v1/saveQuestion`;
export const UPDATE_QUESTION_FOR_ELIGIBILITY = `${API_URL}v1/updateQuestion`;
export const DELETE_QUESTION_FOR_ELIGIBILITY = `${API_URL}v1/deleteQuestion`;
export const SAVE_DRAFT_FOR_ELIGIBILITY = `${API_URL}v1/saveDraft`;
export const SUBMIT_AS_PUBLISHED_ELIGIBILITY = `${API_URL}v1/setPublishedDataByCounty`;
export const SUBMIT_FOR_APPROVAL_ELIGIBLITY = `${API_URL}v1/approvalPending`;
export const GET_PREVIEW_QUESTION = `${API_URL}v1/getQuestionForPreview`;
export const GET_ALL_ELIGIBILITY_FLOWS = `${API_URL}v1/getAllFlows`;

// FAQ AND CATEGORY FORM
export const CREATE_CATEGORY = `${API_URL}v1/saveFaqCategory`;
export const UPDATE_CATEGORY = `${API_URL}v1/updateFaqCategory`;
export const GET_ALL_CATEGORY = `${API_URL}v1/getAllFaqCategory`;
export const GET_ALL_CATEGORY_PAGINATION_FILTER = `${API_URL}v1/getAllFaqCategoryByPaginationAndFilter`;
export const DELETE_CATEGORY = `${API_URL}v1/deleteFaqCategory`;
export const CREATE_SUB_CATEGORY = `${API_URL}v1/saveFaqSubCategory`;
export const UPDATE_SUB_CATEGORY = `${API_URL}v1/updateFaqSubCategory`;
export const GET_ALL_SUB_CATEGORY = `${API_URL}v1/getAllFaqSubCategory`;
export const GET_ALL_SUB_CATEGORY_PAGINATION_FILTER = `${API_URL}v1/getAllFaqSubCategoryByPaginationAndFilter`;
export const GET_ALL_SUB_CATEGORY_BY_CATEGORY_ID = `${API_URL}v1/getAllFaqSubCategoryByCategoryId`;
export const DELETE_SUB_CATEGORY = `${API_URL}v1/deleteFaqSubCategory`;
export const CREATE_FAQ = `${API_URL}v1/saveFaqMaster`;
export const UPDATE_FAQ = `${API_URL}v1/updateFaqMaster`;
export const DELETE_FAQ = `${API_URL}v1/deleteFaqMaster`;
export const GET_ALL_FAQ = `${API_URL}v1/getAllFaqMaster`;
export const GET_ALL_FAQ_PAGINATION_FILTER = `${API_URL}v1/getAllFaqMasterByPaginationAndFilter`;
export const GET_ALL_FAQ_BY_ROLE = `${API_URL}v1/getFaqMasterBasedOnRole`;
export const GET_MY_TICKETS = `${API_URL}v1/getMyTickets`;
export const GET_ALL_TICKETS = `${API_URL}v1/getAllTickets`;
export const GET_ADMIN_ACCESS_FOR_TICKETS = `${API_URL}v1/getAdminAccessForTickets`;
export const SAVE_TICKETS = `${API_URL}v1/saveTicket`;
export const CHANGE_TICKET_PROPERTIES = `${API_URL}v1/changeTicketProperties`;
export const CHANGE_ADMIN_TICKET_STATUS = `${API_URL}v1/changeAdminTicketStatus`;
export const SEND_RESPONSE_TO_CLIENT_TICKET = `${API_URL}v1/sendResponseToClient`;
export const GET_ALL_CATEGORY_FAQ = `${API_URL}v1/getAllFaqCategoryByRole`;
export const GET_ALL_SUB_CATEGORY_FAQ = `${API_URL}v1/getAllFaqSubCategoryByCategoryAndRole`;
export const GET_ALL_TICKET_WITH_FILTERS = `${API_URL}v1/getAllTicketsWithFilters`;
export const SEND_REQUESTED_INFO = `${API_URL}v1/sendRequestedInfo`;

//VIEW CALENDAR TABLE
export const KALEND_TABLE_CONTENTSLOT = `${API_URL}v1/getAvailableDatesBySiteIdAndStartEndDateV1ForSlot`;
export const KALEND_TABLE_CONTENT = `${API_URL}v1/getAvailableDatesBySiteIdAndStartEndDateV1`;

//SITE SCHEDULE CALENDAR
export const SITE_SCHEDULE_TABLE_CONTENT = `${API_URL}v1/getSiteScheduleBySiteIdAndDateV1`;

// REST PASSWORD
export const POST_REST_PASSWORD = `${API_URL}v1/resetPassword`;

//REST PASSWORD WITH EMAIL
export const GET_REST_PASSWORD_WITH_EMAIL = `${API_URL}v1/forgetPasswordEmail`;
export const POST_REST_PASSWORD_WITH_EMAIL = `${API_URL}v1/resetPasswordWithEmail`;

//EDIT PROFILE
export const POST_UPDATE_PROFILE = `${API_URL}v1/updateProfile`;

//EDIT and CANCEL APPOINTMENTS
export const POST_SEARCH_APPOINTMENTS = `${API_URL}v1/searchAppointment`;
export const DELETE_APPOINTMENTS = `${API_URL}v1/deleteAppointment`;
export const UPDATE_APPOINTMENTS = `${API_URL}v1/updateAppointment`;

//DAY VIEW SERVICE
export const DAY_VIEW_GROUPSSLOTS = `${API_URL}v1/getAllScheduleForTaxPreparersBySiteIdAndDateSlot`;
//DAY VIEW SERVICE
export const DAY_VIEW_GROUPS = `${API_URL}v1/getAllScheduleForTaxPreparersBySiteIdAndDate`;

//LEFT BOX VIEW CALENDAR
export const SERVICE_PROVIDERS_DROPDOWN = `${API_URL}v1/getPreparerBySiteId`;
export const SERVICE_TYPE_ADDITIONAL_DROPDOWN = `${API_URL}v1/getSiteServiceTypeMappingBySiteId`;

//dayview individual appointments
export const DAY_VIEW_APPOINTMENTS = `${API_URL}v1/getAllAppointmentsBySiteIdAndDateAndPreparerId`;

//popupgooglepin
export const ZIP_CODE_GOOGLE_MAP = `${API_URL}v1/getNearestSiteByRyZipcodes`;

//SLOTS
export const UPDATE_SLOT_SCHEDULE_AND_SERVICES = `${API_URL}v1/updateSlotScheduleAndServicesV2`;
export const CREATE_EXCEPTION_FOR_SLOTS = `${API_URL}v1/createExceptionForSlots`;

export const GET_SLOTS_BY_SITE_ID = `${API_URL}v1/getSlotsBySiteIdV1`;
export const GET_SERVICE_MAPPING_STATUS_SLOT = `${API_URL}v1/getPreparerServiceMappingStatusBySiteId`;

//APPOINTMENT STATUS
export const APPOINTMENT_STATUS = `${API_URL}v1/changeAppointmentStatus`;
//report
export const REPORT_SUBMIT = `${API_URL}v1/getScheduledAppointmentsBySiteIdAndStartDateEndDate`;
// export const AVAILABLE_SLOTS_REPORT_SUBMIT = `${API_URL}v1/getAvailableSlots`;
export const AVAILABLE_SLOTS_REPORT_SUBMIT = `${API_URL}v1/getAvailableSlotsV1`;
export const GET_DAILY_REPORT = `${API_URL}v1/getDailyXlsReportDownload`;
export const GET_WEEKLY_REPORT = `${API_URL}v1/getWeeklyXlsReportDownload`;
export const GET_MONTHLY_REPORT = `${API_URL}v1/getMonthlyXlsReportDownload`;
export const GET_DAILY_PASTAPPOINTMET_REPORT = `${API_URL}v1/getDailyXlsReportDownloadForPastAppointments`;
export const GET_WEEKLY_PASTAPPOINTMET_REPORT = `${API_URL}v1/getWeeklyXlsReportDownloadForPastAppointments`;
export const GET_MONTHLY_PASTAPPOINTMET_REPORT = `${API_URL}v1/getMonthlyXlsReportDownloadForPastAppointments`;
export const GET_DAILY_CANCELLED_APPOINTMET_REPORT = `${API_URL}v1/getDailyXlsReportDownloadForCancelledAppointments`;
export const GET_WEEKLY_CANCELLED_APPOINTMET_REPORT = `${API_URL}v1/getWeeklyXlsReportDownloadForCancelledAppointments`;
export const GET_MONTHLY_CANCELLED_APPOINTMET_REPORT = `${API_URL}v1/getMonthlyXlsReportDownloadForCancelledAppointments`;
// export const GET_DAILY_AVAILABLE_SLOTS_REPORT = `${API_URL}v1/getDailyXlsReportDownloadForAvailableSlots`;
export const GET_DAILY_AVAILABLE_SLOTS_REPORT = `${API_URL}v1/getDailyXlsReportDownloadForAvailableSlotsv1WithBreakTimes`;
export const GET_CUSTOM_AVAILABLE_SLOTS_REPORT = `${API_URL}v1/getCustomXlsReportDownloadForAvailableSlotsv1WithBreakTimes`;
export const GET_REPORT_HISTORY = `${API_URL}v1/createReportRequest`;
export const GET_HISTORY_DOWNLOAD_REPORT = `${API_URL}v1/downloadReport`;
export const GET_DISABLE_SITE_STATUS = `${API_URL}v1/getHideSiteAndDisableSiteStatusBySiteId`;
export const CHECK_EXISTING_APPOINTMENT = `${API_URL}v1/checkExistingAppointment`;
// export const GET_WEEKLY_AVAILABLE_SLOTS_REPORT = `${API_URL}v1/getWeeklyXlsReportDownloadForAvailableSlots`;
// export const GET_MONTHLY_AVAILABLE_SLOTS_REPORT = `${API_URL}v1/getMonthlyXlsReportDownloadForAvailableSlots`;

// CONFLICT MANAGEMENT
export const CONFLICT_MANAGEMENT = `${API_URL}v1/getSiteScheduleWithConflict`;
export const SITE_LIST_BY_ID = `${API_URL}v1/getSiteListById`;

//LOGS
export const GET_LOGS_BY_SITE_ID = `${API_URL}v1/getLogsBySiteId`;
export const GET_LOGS_BY_SITE_ID_PAGINATION = `${API_URL}v1/getLogsBySiteIdWithPagination`;
