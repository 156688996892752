import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import '@fontsource/roboto';
import { BrowserRouter } from 'react-router-dom/dist';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xsm: 600,
      sm: 800,
      md: 950,
      mlg: 1050,
      lg: 1200,
      xlg: 1400,
      xl: 1536,
    },
  },
  typography: {
    h1: {
      color: '#006699',
      fontSize: '28px !important',
      fontFamily: 'Roboto',
    },
    h2: {
      color: 'black',
      fontSize: '20px !important',
      fontFamily: 'Roboto',
    },
    h3: {
      color: '#006699',
      fontSize: '24px !important',
      fontFamily: 'Roboto',
    },
    body1: {
      fontSize: '18px',
      fontFamily: 'Roboto',
      color: '#000000',
      lineHeight: '1.2',
    },
    createcalendheading: {
      fontSize: '14px',
      fontFamily: 'Roboto',
      color: '#000000',
      lineHeight: '1.2',
    },
    stepsbody: {
      fontSize: '16px',
      fontFamily: 'Roboto',
      color: '#000000',
      lineHeight: '1.2',
    },
    tabtext: {
      margin: 0,
      fontSize: '16px',
      fontFamily: 'Roboto',
      color: '#000000',
      lineHeight: '1.2',
      fontWeight: 'normal',
      letterSpacing: '0.00938em',
      textTransform: 'capitalize',
    },
    body2: {
      fontSize: '18px',
      color: '#000000',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
    },
    stepstypo: {
      fontSize: '18px',
      color: '#000000',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      lineHeight: '0',
    },
    desc: {
      fontSize: '16px',
      color: '#000000',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
    },
    buttonHeader: {
      fontSize: '22px',
      color: '#006699',
      fontFamily: 'Roboto',
    },
    bodylink: {
      fontSize: '16px',
      color: '#006699',
      fontFamily: 'Roboto',
    },
    tabText: {
      color: 'white',
      fontSize: '20px',
      fontFamily: 'Roboto',
      textTransform: 'none',
    },
    tabTextBlue: {
      color: '#006699',
      fontSize: '18px',
      fontFamily: 'Roboto',
      textTransform: 'none',
    },
    bigTitle: {
      color: 'black',
      fontSize: '26px',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
    blackButtonText: {
      fontFamily: 'Roboto',
      // fontWeight: 700,
      color: 'black',
      fontSize: '18px',
    },
    blueButtonText: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      color: '#00cccc',
      fontSize: '20px',
    },
    caption: {
      fontSize: '14px',
      lineHeight: 1.3,
    },
    caption1: {
      fontFamily: 'Roboto',
      color: '#999999',
      fontSize: '16px',
      marginTop: '6px',
    },
    caption2: {
      fontFamily: 'Roboto',
      color: '#666666',
      fontSize: '16px',
    },
    captionRed: {
      fontFamily: 'Roboto',
      color: 'red',
      fontSize: '14px',
    },
    captionx: {
      fontFamily: 'Roboto',
      color: '#666666',
      fontSize: '16px',
    },
    labelText: {
      fontFamily: 'Roboto',
      fontSize: '14px',
    },
    wrapperHeading: {
      color: '#006699',
      fontSize: '20px',
      fontFamily: 'Roboto',
    },
    wrapperHeading2: {
      color: '#006699',
      fontSize: '16px',
      fontFamily: 'Roboto',
    },
    wrapperHeading4: {
      color: '#006699',
      fontSize: '18px',
      fontFamily: 'Roboto',
    },
  },
  palette: {
    lightBlue: '#99ffff',
    normalBlue: '#00cccc',
    darkBlue: '#006699',
    lightBlue2: '#e1f9f8',
    lightBlue3: '#ccffff',
    normalBlue2: '#3399cc',
    gray: '#EBECED',
    darkGray: '#999999',
    orange: '#ff9933',
    black: '#000000',
    white: '#ffffff',
    verylightBlue: '#e1f9f8',
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          '&[style*="opacity: 0"]': {
            pointerEvents: 'none',
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <App />
          </LocalizationProvider>
        </BrowserRouter>
      </StyledEngineProvider>
    </ThemeProvider>
  </Provider>
);
