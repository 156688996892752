import { createSlice } from '@reduxjs/toolkit';
import { formArrFromTree } from '../../util';

function parseLocalStorageItem(key, defaultValue) {
  const value = localStorage.getItem(key);

  try {
    return value ? JSON.parse(value) : defaultValue;
  } catch (error) {
    // Handle the error (e.g., log it) or return the defaultValue.
    console.error(`Error parsing JSON from localStorage for key '${key}': ${error}`);
    return defaultValue;
  }
}
const userSlice = createSlice({
  name: 'user',
  initialState: {
    accessToken: '',
    refreshToken: '',
    info: parseLocalStorageItem('schedulerLoggedInUser', null),
    role: 'public',
    globalMenu: parseLocalStorageItem('211GlobalMenu', null),
    activeParentMenuId: parseLocalStorageItem('211ParentId', ''),
    secondaryActiveParentMenuId: parseLocalStorageItem('211SecondaryParentId', ''),
  },
  reducers: {
    setInfo: (state, action) => {
      state.info = action.payload;
      localStorage.setItem('schedulerLoggedInUser', JSON.stringify(action.payload));
    },
    setGlobalMenu: (state, action) => {
      let reqd = formArrFromTree(action.payload, state.info);

      // Update the title to "Availability" if it's "Service Provider Details"
      let user = JSON.parse(localStorage.getItem('schedulerLoggedInUser'));

      if (user?.multiple_sites == 1) {
        state.globalMenu = reqd.map((item) => {
          if (item.title === 'Service Provider Details') {
            return { ...item, title: 'Availability', route: '/site-coordinator/home/setup-details/availability' };
          }
          return item;
        });
        localStorage.setItem('211GlobalMenu', JSON.stringify(state.globalMenu));
      } else {
        if (user?.appointment_type === 1) {
          state.globalMenu = reqd.map((item) => {
            if (item.title === 'Service Provider Details') {
              return { ...item, title: 'Availability', route: '/site-coordinator/home/setup-details/slot-availability' };
            }
            return item;
          });
          localStorage.setItem('211GlobalMenu', JSON.stringify(state.globalMenu));
        } else {
          state.globalMenu = reqd;
          localStorage.setItem('211GlobalMenu', JSON.stringify(reqd));
        }
      }
    },
    setActiveParentMenuId: (state, action) => {
      state.activeParentMenuId = action.payload;
      localStorage.setItem('211ParentId', JSON.stringify(action.payload));
    },
    setSecondaryActiveParentMenuId: (state, action) => {
      state.secondaryActiveParentMenuId = action.payload;
      localStorage.setItem('211SecondaryParentId', JSON.stringify(action.payload));
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
      localStorage.setItem('access', action.payload);
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
      localStorage.setItem('211refresh', action.payload);
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
  },
});

export const { setAccessToken, setInfo, setRole, setRefreshToken, setGlobalMenu, setActiveParentMenuId, setSecondaryActiveParentMenuId } =
  userSlice.actions;
export default userSlice.reducer;
