import React, { lazy, Suspense } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Loader from './components/Loader';
import httpService from './AxiosSetup/axios-interceptors';

httpService.setupInterceptors();

const Public = lazy(() => import('./views/Public'));

const ProfileSetupAndSecurity = lazy(() => import('./views/Global/ProfileSetupAndSecurity'));
const ConflictManagement = lazy(() => import('./views/userTypes/SiteCoordinator/Conflict Management'));
const ResetPassword = lazy(() => import('./views/Global/ResetPassword'));

const Registration1 = lazy(() => import('./views/Public/Registration/registration1'));
const Registration2 = lazy(() => import('./views/Public/Registration/registration2'));
const Registration3 = lazy(() => import('./views/Public/Registration/registration3'));

const Login = lazy(() => import('./views/Public/Login'));

const Navigator = lazy(() => import('./views/userTypes/Navigator'));
const PageNotFound = lazy(() => import('./views/userTypes/PageNotFound'));
const NavigatorHome = lazy(() => import('./views/userTypes/Navigator/Home'));
const NavigatorNewAppointment = lazy(() => import('./views/userTypes/Navigator/NewAppointment'));
const NavigatorSearchAppointment = lazy(() => import('./views/userTypes/Navigator/SearchAppointment'));
const Support = lazy(() => import('./views/userTypes/Navigator/Support'));
//serviceProvider
const ServiceProvider = lazy(() => import('./views/userTypes/ServiceProvider/Home'));

// //serviceProvider
// const ServiceProvider = lazy(() => import('./views/userTypes/ServiceProvider/Home'));

const SiteCoordinator = lazy(() => import('./views/userTypes/SiteCoordinator'));
const SiteCoordinatorHome = lazy(() => import('./views/userTypes/SiteCoordinator/Home'));

const SiteCoordinatorSetupDetails = lazy(() => import('./views/userTypes/SiteCoordinator/SetupDetails'));
const SiteCoordinatorSetupSite = lazy(() => import('./views/userTypes/SiteCoordinator/SetupDetails/views/Site'));

const SiteCoordinatorSetup = lazy(() => import('./views/userTypes/SiteCoordinator/Setup'));

const SiteCoordinatorAppointments = lazy(() => import('./views/userTypes/SiteCoordinator/Appointments'));

const SiteCoordinatorSetupUser = lazy(() => import('./views/userTypes/SiteCoordinator/SetupDetails/views/Users'));
const SiteCoordinatorSetupPreparerAvailability = lazy(() =>
  import('./views/userTypes/SiteCoordinator/SetupDetails/views/PreparerAvailability')
);
const SiteCoordinatorSetupPreparerAvailabilityServices = lazy(() =>
  import('./views/userTypes/SiteCoordinator/SetupDetails/views/PreparerAvailability/views/preparerServices')
);
const SiteCoordinatorSetupPreparerAvailabilitySchedule = lazy(() =>
  import('./views/userTypes/SiteCoordinator/SetupDetails/views/PreparerAvailability/views/preparerSchedule')
);

const SiteCoordinatorSetupSlotAvailability = lazy(() => import('./views/userTypes/SiteCoordinator/SetupDetails/views/SlotAvailability'));
const SiteCoordinatorSetupSlotAvailabilitySchedule = lazy(() =>
  import('./views/userTypes/SiteCoordinator/SetupDetails/views/SlotAvailability/views/slotSchedule')
);
const SiteCoordinatorSetupSlotAVailabilityServiceProvider = lazy(() =>
  import('./views/userTypes/SiteCoordinator/SetupDetails/views/SlotAvailability/views/slotServiceProviders')
);
const SlotViewCalendar = lazy(() => import('./views/userTypes/SiteCoordinator/SetupDetails/views/SlotAvailability/views/Calendar'));

const SiteCoordinatorAppointmentNewAppointment = lazy(() => import('./views/userTypes/SiteCoordinator/Appointments/views/NewAppointment'));
const SiteCoordinatorAppointmentSearchAppointment = lazy(() =>
  import('./views/userTypes/SiteCoordinator/Appointments/views/SearchAppointment')
);
const SiteCoordinatorAppointmentCalendar = lazy(() => import('./views/userTypes/SiteCoordinator/Appointments/views/Calendar'));
const SiteCoordinatorReports = lazy(() => import('./views/userTypes/SiteCoordinator/Reports'));
const SiteCoordinatoDayCalendar = lazy(() => import('./components/Calendar/DayCalendar/DayCalendar'));
const SiteCoordinatorAppointmentDayview = lazy(() =>
  import('./views/userTypes/SiteCoordinator/Appointments/views/Calendar/AppointementDayView')
);
const CheckEligibility = lazy(() => import('./views/userTypes/SiteCoordinator/Appointments/views/Eligibility'));
const ClientAdmin = lazy(() => import('./views/userTypes/ClientAdmin'));
const ClientAdminHome = lazy(() => import('./views/userTypes/ClientAdmin/Home'));
const ClientAdminBeforeHome = lazy(() => import('./views/userTypes/ClientAdmin/BeforeHome'));
const ClientAdminCopyMasterData = lazy(() => import('./views/userTypes/ClientAdmin/CopyMasterData'));
const ClientAdminSetup = lazy(() => import('./views/userTypes/ClientAdmin/Setup'));

const ClientAdminSetupDetails = lazy(() => import('./views/userTypes/ClientAdmin/SetupDetails'));

const ClientAdminSetupRegionSites = lazy(() => import('./views/userTypes/ClientAdmin/SetupDetails/views/RegionSites'));
const ClientAdminSetupRegionSitesCreateEditRegion = lazy(() =>
  import('./views/userTypes/ClientAdmin/SetupDetails/views/RegionSites/views/createEditRegion')
);
const ClientAdminSetupRegionSitesCreateEditSites = lazy(() =>
  import('./views/userTypes/ClientAdmin/SetupDetails/views/RegionSites/views/createEditSites')
);
const ClientAdminSetupRegionSitesSiteConfiguration = lazy(() =>
  import('./views/userTypes/ClientAdmin/SetupDetails/views/RegionSites/views/siteConfiguration')
);
const ClientAdminCategory = lazy(() => import('./views/userTypes/Navigator/Support/Category&Faq'));
const ClientAdminCreateCategory = lazy(() => import('./views/userTypes/Navigator/Support/Category&Faq/views/createCategory'));
const ClientAdminCreateSubCategory = lazy(() => import('./views/userTypes/Navigator/Support/Category&Faq/views/createSubCategory'));
const ClientAdminCreateFaq = lazy(() => import('./views/userTypes/Navigator/Support/Category&Faq/views/createFaq'));
const ClientAdminSetupServiceTypes = lazy(() => import('./views/userTypes/ClientAdmin/SetupDetails/views/ServiceTypes'));
const ServiceTypespage = lazy(() => import('./views/userTypes/ClientAdmin/SetupDetails/views/ServiceTypes2'));
const TrainingVideo = lazy(() => import('./views/userTypes/ClientAdmin/SetupDetails/views/trainingVideo'));
const Quicklinkservicetype = lazy(() => import('./views/userTypes/ClientAdmin/Setup/index2'));
const ClientAdminSetupUser = lazy(() => import('./views/userTypes/ClientAdmin/SetupDetails/views/Users'));
const ClientAdminSetupUserInviteUsers = lazy(() => import('./views/userTypes/ClientAdmin/SetupDetails/views/Users/views/inviteUsers'));
const ClientAdminSetupUserCreateRoles = lazy(() => import('./views/userTypes/ClientAdmin/SetupDetails/views/Users/views/createRoles'));
const ClientAdminSetupUserAssignRole = lazy(() => import('./views/userTypes/ClientAdmin/SetupDetails/views/Users/views/rolePermissions'));
const ClientAdminSetupUserMapGeography = lazy(() =>
  import('./views/userTypes/ClientAdmin/SetupDetails/views/Users/views/mapUserToGeography')
);
const ClientAdminSetupRoleRights = lazy(() => import('./views/userTypes/ClientAdmin/SetupDetails/views/RoleRights'));
const ClientAdminEligibility = lazy(() => import('./views/userTypes/ClientAdmin/SetupDetails/views/Eligibility'));
// const ClientAdminSetupSite  = lazy(() => import("./views/userTypes/ClientAdmin/SetupDetails/views/Users"));
// const ClientAdminSetupPreparerAvailability  = lazy(() => import("./views/userTypes/ClientAdmin/SetupDetails/views/Users"));

const RestPasswordEmail = lazy(() => import('./views/Global/ResetWithEmail'));
const ChangePassword = lazy(() => import('./views/Global/ChangePassword'));

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Public />}>
          <Route path="rest-password" element={<RestPasswordEmail />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="registration-s1" element={<Registration1 />} />
          <Route path="registration-s2" element={<Registration2 />} />
          <Route path="registration-s3" element={<Registration3 />} />
        </Route>

        <Route path="/" element={<Login />}>
          <Route path="login" element={<Login />} />

          {/* <Route path="*" element={<PageNotFound />} /> */}
        </Route>

        {/* navigator */}
        <Route path="/navigator" element={<Navigator />}>
          <Route path="edit-profile" element={<ProfileSetupAndSecurity></ProfileSetupAndSecurity>} />
          <Route path="reset-password" element={<ResetPassword></ResetPassword>} />
          <Route path="home" element={<NavigatorHome />} />
          <Route path="home/create-new-appointment" element={<NavigatorNewAppointment />} />
          <Route path="home/edit-cancel-appointment" element={<NavigatorSearchAppointment />} />
          <Route path="home/support" element={<Support />} />
          <Route path="home/view-service-types" element={<ServiceTypespage />} />
          <Route path="home/Training-video" element={<TrainingVideo />} />
          {/* <Route path="*" element={<PageNotFound />} /> */}
        </Route>
        {/*service provider*/}
        <Route path="/service-provider/home" element={<ServiceProvider />} />

        {/*service provider*/}
        <Route path="/service-provider/home" element={<ServiceProvider />} />

        {/* site coordinator */}
        {/* <Route path="//site-coordinator" element={<SiteCoordinator />}>
          <Route path="edit-profile" element={<ProfileSetupAndSecurity></ProfileSetupAndSecurity>} />
          <Route path="conflict" element={<ConflictManagement></ConflictManagement>} />
          <Route path="reset-password" element={<ResetPassword></ResetPassword>} />
          <Route path="home" element={<SiteCoordinatorHome />} />

          <Route path="home/setup-details" element={<SiteCoordinatorSetupDetails />}>
            <Route path="site" element={<SiteCoordinatorSetupSite />}>
              <Route path="site-schedule" element={<SiteCoordinatorSetupSite />} />
            </Route>
            <Route path="users" element={<SiteCoordinatorSetupUser />}>
              <Route path="invite-users" element={<ClientAdminSetupUserInviteUsers />} />
              <Route path="map-user-to-geography" element={<ClientAdminSetupUserMapGeography type="site-coordinator" />} />
            </Route>
            <Route path="slot-availability" element={<SiteCoordinatorSetupSlotAvailability />}>
              <Route path="schedule" element={<SiteCoordinatorSetupSlotAvailabilitySchedule />} />
              <Route path="service-providers" element={<SiteCoordinatorSetupSlotAVailabilityServiceProvider />} />
              <Route path="view-slot-calendar" element={<SlotViewCalendar />} />
            </Route>
            <Route path="service-provider-availability" element={<SiteCoordinatorSetupPreparerAvailability />}>
              <Route path="service-provider-services" element={<SiteCoordinatorSetupPreparerAvailabilityServices />} />
              <Route path="service-provider-schedule" element={<SiteCoordinatorSetupPreparerAvailabilitySchedule />} />
            </Route>
            <Route path="service-provider-details" element={<SiteCoordinatorSetupPreparerAvailability />}>
              <Route path="service-provider-services" element={<SiteCoordinatorSetupPreparerAvailabilityServices />} />
              <Route path="service-provider-schedule" element={<SiteCoordinatorSetupPreparerAvailabilitySchedule />} />
            </Route>
            <Route path="site/dayview" element={<SiteCoordinatoDayCalendar />} />

          </Route>
          <Route path="home/setup" element={<SiteCoordinatorSetup />} />
          <Route path="home/appointments" element={<SiteCoordinatorAppointments />}>
            <Route path="create-new-appointment" element={<SiteCoordinatorAppointmentNewAppointment />} />
            <Route path="edit-cancel-appointment" element={<SiteCoordinatorAppointmentSearchAppointment />} />
            <Route path="view-calendar" element={<SiteCoordinatorAppointmentCalendar />} />
            <Route path="check-eligibility" element={<CheckEligibility />} />

            <Route path="view-calendar/dayview" element={<SiteCoordinatorAppointmentDayview />} />

          </Route>
          <Route path="home/reports" element={<SiteCoordinatorReports />} />
          <Route path="home/support" element={<Support />} />

        </Route> */}

        {['site-coordinator', 'county-coordinator', 'regional-coordinator'].map((roleItem) => (
          <>
            <Route path={`/${roleItem}`} element={<SiteCoordinator />}>
              <Route path="edit-profile" element={<ProfileSetupAndSecurity></ProfileSetupAndSecurity>} />
              <Route path="conflict" element={<ConflictManagement></ConflictManagement>} />
              <Route path="reset-password" element={<ResetPassword></ResetPassword>} />
              <Route path="home" element={<SiteCoordinatorHome />} />
              <Route path="home/setup-details" element={<ClientAdminSetupDetails />}>
                <Route path="site" element={<SiteCoordinatorSetupSite />}>
                  <Route path="site-schedule" element={<SiteCoordinatorSetupSite />} />
                </Route>
                <Route path="sites" element={<SiteCoordinatorSetupSite />}>
                  <Route path="site-schedule" element={<SiteCoordinatorSetupSite />} />
                </Route>
                <Route path="users" element={<SiteCoordinatorSetupUser />}>
                  <Route path="invite-users" element={<ClientAdminSetupUserInviteUsers />} />
                  <Route path="map-user-to-geography" element={<ClientAdminSetupUserMapGeography type="site-coordinator" />} />
                </Route>
                <Route path="slot-availability" element={<SiteCoordinatorSetupSlotAvailability />}>
                  <Route path="schedule" element={<SiteCoordinatorSetupSlotAvailabilitySchedule />} />
                  <Route path="service-providers" element={<SiteCoordinatorSetupSlotAVailabilityServiceProvider />} />
                  <Route path="view-slot-calendar" element={<SlotViewCalendar />} />
                </Route>
                <Route path="availability" element={<SiteCoordinatorSetupPreparerAvailability type="client-admin" />}>
                  <Route path="slot-availability" element={<SiteCoordinatorSetupSlotAvailability type="client-admin" />}>
                    <Route path="schedule" element={<SiteCoordinatorSetupSlotAvailabilitySchedule />} />
                    <Route path="service-providers" element={<SiteCoordinatorSetupSlotAVailabilityServiceProvider />} />
                    <Route path="view-slot-calendar" element={<SlotViewCalendar />} />
                  </Route>
                  <Route path="service-provider-services" element={<SiteCoordinatorSetupPreparerAvailabilityServices />} />
                  <Route path="service-provider-schedule" element={<SiteCoordinatorSetupPreparerAvailabilitySchedule />} />
                </Route>
                <Route path="service-provider-availability" element={<SiteCoordinatorSetupPreparerAvailability />}>
                  <Route path="service-provider-services" element={<SiteCoordinatorSetupPreparerAvailabilityServices />} />
                  <Route path="service-provider-schedule" element={<SiteCoordinatorSetupPreparerAvailabilitySchedule />} />
                </Route>
                <Route path="service-provider-details" element={<SiteCoordinatorSetupPreparerAvailability />}>
                  <Route path="service-provider-services" element={<SiteCoordinatorSetupPreparerAvailabilityServices />} />
                  <Route path="service-provider-schedule" element={<SiteCoordinatorSetupPreparerAvailabilitySchedule />} />
                </Route>
                <Route path="site/dayview" element={<SiteCoordinatoDayCalendar />} />

                {/* copied from client admin, need to keep an eye for duplicacy */}
                <Route path="roles-&-rights" element={<ClientAdminSetupRoleRights />}>
                  <Route path="create-roles" element={<ClientAdminSetupUserCreateRoles />} />
                  <Route path="assign-role-permissions" element={<ClientAdminSetupUserAssignRole />} />
                </Route>
                <Route path="region-&-sites" element={<ClientAdminSetupRegionSites />}>
                  <Route path="create-edit-regions" element={<ClientAdminSetupRegionSitesCreateEditRegion />} />
                  <Route path="create-edit-region" element={<ClientAdminSetupRegionSitesCreateEditRegion />} />
                  <Route path="create-edit-sites" element={<ClientAdminSetupRegionSitesCreateEditSites />} />
                  {/* <Route path="site-configuration" element={<ClientAdminSetupRegionSitesSiteConfiguration />} /> */}
                  <Route path="site-configuration" element={<ClientAdminSetupRegionSitesSiteConfiguration />}>
                    <Route path="site-schedule" element={<SiteCoordinatorSetupSite />} />
                  </Route>
                </Route>
                <Route path="service-types" element={<ClientAdminSetupServiceTypes />} />
                <Route path="site" element={<SiteCoordinatorSetupSite />} />
                <Route path="servicetype-data" element={<Quicklinkservicetype />} />
                {/* <Route path="availability" element={<SiteCoordinatorSetupPreparerAvailability type="client-admin" />}>
                  <Route path="service-provider-services" element={<SiteCoordinatorSetupPreparerAvailabilityServices />} />
                  <Route path="service-provider-schedule" element={<SiteCoordinatorSetupPreparerAvailabilitySchedule />} />
                </Route> */}
                <Route path="eligibility" element={<ClientAdminEligibility />} />
                <Route path="site/dayview" element={<SiteCoordinatoDayCalendar />} />
                {/* <Route path="*" element={<PageNotFound />} /> */}
              </Route>

              <Route path="home/setup" element={<SiteCoordinatorSetup />} />
              <Route path="home/appointments" element={<SiteCoordinatorAppointments />}>
                <Route path="create-new-appointment" element={<SiteCoordinatorAppointmentNewAppointment />} />
                <Route path="edit-cancel-appointment" element={<SiteCoordinatorAppointmentSearchAppointment />} />
                <Route
                  path="view-calendar"
                  element={<SiteCoordinatorAppointmentCalendar type={roleItem === 'regional-coordinator' ? 'clientAdmin' : ''} />}
                />
                <Route path="check-eligibility" element={<CheckEligibility />} />

                <Route path="view-calendar/dayview" element={<SiteCoordinatorAppointmentDayview />} />
                {/* <Route path="*" element={<PageNotFound />} /> */}
              </Route>
              <Route path="home/reports" element={<SiteCoordinatorReports />} />
              <Route path="home/support" element={<Support />} />
              <Route path="home/view-service-types" element={<ServiceTypespage />} />
              <Route path="home/Training-video" element={<TrainingVideo />} />
              <Route path="home/setup/view-service-types" element={<ServiceTypespage />} />
              {/* <Route path="*" element={<PageNotFound />} /> */}
            </Route>
          </>
        ))}

        {/* client admin */}
        <Route path="/client-admin" element={<ClientAdmin />}>
          <Route path="conflict" element={<ConflictManagement></ConflictManagement>} />
          <Route path="home/support" element={<Support />} />
          <Route path="home/support" element={<Outlet />}>
            <Route path="category" element={<ClientAdminCategory />}>
              <Route path="create-category" element={<ClientAdminCreateCategory />} />
              <Route path="create-sub-category" element={<ClientAdminCreateSubCategory />} />
              <Route path="create-faq" element={<ClientAdminCreateFaq />} />
            </Route>
          </Route>
          <Route path="edit-profile" element={<ProfileSetupAndSecurity></ProfileSetupAndSecurity>} />
          <Route path="reset-password" element={<ResetPassword></ResetPassword>} />
          <Route path="home" element={<ClientAdminHome />} />
          <Route path="initial-setup" element={<ClientAdminBeforeHome />} />
          <Route path="home/copy-master-data" element={<ClientAdminCopyMasterData />} />
          <Route path="home/setup-details" element={<ClientAdminSetupDetails />}>
            <Route path="users" element={<ClientAdminSetupUser />}>
              <Route path="invite-users" element={<ClientAdminSetupUserInviteUsers />} />
              <Route path="map-user-to-geography" element={<ClientAdminSetupUserMapGeography />} />
            </Route>
            <Route path="roles-&-rights" element={<ClientAdminSetupRoleRights />}>
              <Route path="create-roles" element={<ClientAdminSetupUserCreateRoles />} />
              <Route path="assign-role-permissions" element={<ClientAdminSetupUserAssignRole />} />
            </Route>
            <Route path="region-&-sites" element={<ClientAdminSetupRegionSites />}>
              <Route path="create-edit-regions" element={<ClientAdminSetupRegionSitesCreateEditRegion />} />
              <Route path="create-edit-sites" element={<ClientAdminSetupRegionSitesCreateEditSites />} />
              <Route path="site-configuration" element={<ClientAdminSetupRegionSitesSiteConfiguration />}>
                <Route path="site-schedule" element={<SiteCoordinatorSetupSite />} />
              </Route>
            </Route>
            <Route path="service-types" element={<ClientAdminSetupServiceTypes />} />
            {/* <Route path="site" element={<SiteCoordinatorSetupSite />} /> */}
            <Route path="servicetype-data" element={<Quicklinkservicetype />} />
            <Route path="availability" element={<SiteCoordinatorSetupPreparerAvailability type="client-admin" />}>
              <Route path="slot-availability" element={<SiteCoordinatorSetupSlotAvailability type="client-admin" />}>
                <Route path="schedule" element={<SiteCoordinatorSetupSlotAvailabilitySchedule />} />
                <Route path="service-providers" element={<SiteCoordinatorSetupSlotAVailabilityServiceProvider />} />
                <Route path="view-slot-calendar" element={<SlotViewCalendar />} />
              </Route>
              <Route path="service-provider-services" element={<SiteCoordinatorSetupPreparerAvailabilityServices />} />
              <Route path="service-provider-schedule" element={<SiteCoordinatorSetupPreparerAvailabilitySchedule />} />
            </Route>

            <Route path="eligibility" element={<ClientAdminEligibility />} />
            <Route path="site/dayview" element={<SiteCoordinatoDayCalendar />} />
            {/* <Route path="*" element={<PageNotFound />} /> */}
          </Route>
          <Route path="home/setup" element={<ClientAdminSetup />} />
          <Route path="home/appointments" element={<SiteCoordinatorAppointments from="client-admin" />}>
            <Route path="create-new-appointment" element={<SiteCoordinatorAppointmentNewAppointment />} />
            <Route path="edit-cancel-appointment" element={<SiteCoordinatorAppointmentSearchAppointment />} />
            <Route path="view-calendar" element={<SiteCoordinatorAppointmentCalendar type="clientAdmin" />} />
            <Route path="check-eligibility" element={<CheckEligibility />} />

            {/* <Route path="*" element={<PageNotFound />} /> */}
          </Route>

          <Route path="home/reports" element={<SiteCoordinatorReports />} />
          <Route path="home/view-service-types" element={<ServiceTypespage />} />
          <Route path="home/Training-video" element={<TrainingVideo />} />

          {/* <Route path="*" element={<PageNotFound />} /> */}
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
