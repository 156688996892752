import { createSlice } from '@reduxjs/toolkit';
import { exceptiomsBuildData, scheduleBuildData } from '../../util';

const siteCoordinatorClientRequirementSlice = createSlice({
  name: 'siteCoordinatorClientRequirements',
  initialState: {
    values: {},
    confirmedValues: {},
    date: '',
    time: '',
    data: {},
    activeTaxPreparerId: '',
    searchAppointmnetData: {},
    appointmnetDialog: {
      appoint: false,
      client: false,
    },
    sitebasicData: {},
    additionalservtypearray: [],
    slots: {
      init: [],
      availability: [],
      siteService: [],
      updatedPrefilledData: [],
      exceptionfulfilled: [],
    },
    navigator: {
      eligibilty: {},
      eligibilityForm: {},
    },
    updateResponse: {},
    reportStatusinReports: '',
    globalsite: '',
    goButtonCaller: 1,
    siteiddata: '',
    siteidlabel: '',
    editinitstartdate: '',
    editinitenddate: '',
    leftboxeditsiteid: '',
    globalsiteName: '',
    setservtypenamenavigator: '',
    acpEligibilityParams: {},
  },
  reducers: {
    setSiteCoordinatorClientRequirementsDataConfirmed: (state, action) => {
      state.confirmedValues = action.payload;
    },
    setSiteCoordinatorClientRequirementsData: (state, action) => {
      state.values = action.payload;
    },
    setSiteCoordinatorDate: (state, action) => {
      state.date = action.payload;
    },
    setSitebasicData: (state, action) => {
      state.sitebasicData = action.payload;
    },
    setSiteCoordinatorTime: (state, action) => {
      state.time = action.payload;
    },
    editAppointmentData: (state, action) => {
      state.data = action.payload;
    },
    siteidData: (state, action) => {
      state.siteiddata = action.payload;
    },
    siteidLabel: (state, action) => {
      state.siteidlabel = action.payload;
    },
    editinitstartDate: (state, action) => {
      state.editinitstartdate = action.payload;
    },
    editinitendDate: (state, action) => {
      state.editinitenddate = action.payload;
    },
    leftboxeditSiteid: (state, action) => {
      state.leftboxeditsiteid = action.payload;
    },
    setActiveTaxPreparer: (state, action) => {
      state.activeTaxPreparerId = action.payload;
    },
    setSearchedAppointmentData: (state, action) => {
      state.searchAppointmnetData = action.payload;
    },
    setAppointmnetDetailsDialog: (state, action) => {
      state.appointmnetDialog = action.payload;
    },
    setSlot: (state, action) => {
      state.slots = action.payload;
    },
    setGlobalSite: (state, action) => {
      state.globalsite = action.payload;
    },
    setGlobalSiteName: (state, action) => {
      state.globalsiteName = action.payload;
    },
    setNavigator: (state, action) => {
      state.navigator = action.payload;
    },
    setGoButtonCaller: (state, action) => {
      state.goButtonCaller = state.goButtonCaller + 1;
    },
    additionalservtypeArray: (state, action) => {
      state.additionalservtypearray = action.payload;
    },
    setservtypenameNavigator: (state, action) => {
      state.setservtypenamenavigator = action.payload;
    },
    logout: (state) => {
      state = this.initialState;
    },
    setAcpEligibilityParams: (state, action) => {
      state.acpEligibilityParams = {
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase('getSiteBasic/fulfilled', (state, action) => {
      state.slots.availability = action?.payload?.siteSchedulesMasterList;
      state.slots.init = scheduleBuildData(action?.payload?.siteSchedulesMasterList || []);
      state.slots.exceptionfulfilled = exceptiomsBuildData(action?.payload?.siteSchedulesMasterList || []);
    });
    builder.addCase('getSiteBasicWithoutSlotCall/fulfilled', (state, action) => {
      state.slots.availability = action?.payload?.siteSchedulesMasterList;
      // state.slots.init = scheduleBuildData(action?.payload?.siteSchedulesMasterList || []);
      // state.slots.exceptionfulfilled = exceptiomsBuildData(action?.payload?.siteSchedulesMasterList || []);
    });
    builder.addCase('getSlotsBySiteid/fulfilled', (state, action) => {
      state.slots.updatedPrefilledData = action.payload;
      state.slots.init = scheduleBuildData(state.slots.availability, action.payload);
      state.slots.exceptionfulfilled = exceptiomsBuildData(state.slots.availability, action.payload);
    });
    builder.addCase('getSiteServiceTypeMappingBySiteid/fulfilled', (state, action) => {
      state.slots.siteService = action.payload;
    });
    builder
      .addCase('updateSlotScheduleServicesV2/fulfilled', (state, action) => {
        state.slots.updateResponse = action.payload;
      })
      .addCase('updateSlotException/fulfilled', (state, action) => {
        state.slots.exceptionfulfilled = action.payload;
      });
    builder.addCase('updateStatusofReport/fulfilled', (state, action) => {
      state.reportStatusinReports = action.payload;
    });
    builder.addCase('getRegionListByClientIdAndCountyToken/fulfilled', (state, action) => {
      state.navigator.eligibilty = action.payload;
    });
  },
});

export const {
  setSiteCoordinatorClientRequirementsDataConfirmed,
  setSiteCoordinatorClientRequirementsData,
  setSiteCoordinatorDate,
  setSiteCoordinatorTime,
  editAppointmentData,
  setActiveTaxPreparer,
  setSearchedAppointmentData,
  setAppointmnetDetailsDialog,
  setSlot,
  setGlobalSite,
  siteidData,
  siteidLabel,
  editinitstartDate,
  editinitendDate,
  setGlobalSiteName,
  setNavigator,
  leftboxeditSiteid,
  setGoButtonCaller,
  additionalservtypeArray,
  setservtypenameNavigator,
  setSitebasicData,
  setAcpEligibilityParams,
} = siteCoordinatorClientRequirementSlice.actions;
export default siteCoordinatorClientRequirementSlice.reducer;
